@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 249 66.9% 52.5%;
    --primary-foreground: 0 0% 98%;
    --secondary: 249 67.1% 84.5%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

.smooth-toast {
  transition: all 0.3s ease-in-out !important; // use your desired timing and easing function
  opacity: 0.9;
}

@font-face {
  font-family: 'Neco';
  src: url('../Fonts/Neco-Regular.otf');
}

@font-face {
  font-family: 'Rubik';
  src: url('../Fonts/Rubik-Regular.woff2');
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  .noArrows::-webkit-outer-spin-button,
  .noArrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .noArrows {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer components {
  body,
  html {
    @apply customScroll h-full overflow-auto font-rubik sm:h-screen sm:overflow-hidden;
  }
  iframe {
    max-width: 100%;
  }
  @screen sm {
    .customScroll::-webkit-scrollbar {
      @apply h-5 w-5;
    }
    .customScroll::-webkit-scrollbar-track,
    ::-webkit-scrollbar-corner {
      @apply bg-transparent;
    }
    .customScroll::-webkit-scrollbar-thumb {
      @apply rounded-full border-[.3rem] border-solid border-transparent shadow-[inset_0_0_10px_10px] shadow-gray-300;
    }
  }
  .v_h1,
  h1 {
    @apply font-neco text-[64px] font-bold leading-[80px] tracking-[-2%];
  }
  .v_h2,
  h2 {
    @apply font-neco text-[48px] font-bold leading-[60px] tracking-[-2%];
  }
  .v_h3,
  h3 {
    @apply font-neco text-[40px] font-bold leading-[56px] tracking-[-2%];
  }
  .v_h4,
  h4 {
    @apply font-neco text-[28px] font-bold leading-[36px] tracking-[-2%];
  }
  .v_h5,
  h5 {
    @apply font-neco text-[24px] font-bold leading-[36px] tracking-[-2%];
  }
  .v_h6,
  h6 {
    @apply font-neco text-[18px] font-bold leading-[26px] tracking-[-2%];
  }
  .v_body_reg {
    @apply font-rubik text-[20px] font-bold leading-[30px] tracking-[0%];
  }
  .v_body_2_reg {
    @apply font-rubik text-[16px] font-normal leading-[24px] tracking-[0%];
  }
  .v_body_med {
    @apply font-rubik text-[20px] font-medium leading-[30px] tracking-[0%];
  }
  .v_body_2_med {
    @apply font-rubik text-[16px] font-medium leading-[24px] tracking-[0%];
  }
  .v_button_text_small {
    @apply font-rubik text-[16px] font-medium leading-[24px] tracking-[0%];
  }
  .v_button_text_large {
    @apply font-rubik text-[18px] font-medium leading-[28px] tracking-[0%];
  }
  .v_button {
    @apply flex flex-row items-center justify-center px-[28px] py-[12px] hover:cursor-pointer hover:no-underline;
  }
  .v_button_light {
    @apply bg-voca-purple text-white;
  }
  .v_button_dark {
    @apply bg-voca-purple-700 text-white;
  }
}
/* TODO: added globally for speed select. Should find out how to put in that css module/file */
.MuiSlider-valueLabelOpen {
  background-color: white #{!important};
  color: black #{!important};
  border: 1px solid black #{!important};
}

.textLayer {
  //opacity: 1 !important;
  br {
    user-select: none;
  }
}
.pdfViewer .page {
  border-radius: var(--radius);
  overflow: hidden #{!important};
  border: none #{!important};
  margin-bottom: 1rem #{!important};
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
  ::selection {
    background-color: #00e668;
  }
}
.sentence {
  @apply bg-transparent text-foreground;
  border-radius: 2px;
}
.sentence:hover {
  background-color: #f4e9ff;
}
.dark .sentence:hover {
  background-color: #462367;
}
.sentence[data-reading='true'] {
  background-color: #d9b5ff;
}
.dark .sentence[data-reading='true'] {
  background-color: #462367;
}
.sentenceDark[data-reading='true'] {
  background-color: #75489f;
  color: #d9b5ff;
}
.sentence[data-reading='true'][data-hovering='true'] {
  background-color: #c994ff;
}
.sentenceDark[data-reading='true'][data-hovering='true'] {
  background-color: #8e5db9;
  color: #d9b5ff;
}

.pdf-sentence {
  background-color: transparent;
  color: transparent;
  border-radius: 2px;
}
.pdf-sentence[data-hovering='true'] {
  background-color: #d3a7ff;
}
.pdf-sentence[data-reading='true'] {
  background-color: #6700ff;
}
.pdf-sentence[data-reading='true'][data-hovering='true'] {
  background-color: #2700ff;
}

.placeHolder-0 {
  @apply text-gray-600;
}
.placeHolder-1 {
  @apply text-black;
}
.textLayer {
  @apply pointer-events-none;
}
.textLayer * {
  @apply pointer-events-auto;
}
.selected {
  background: #c8dfff;
}

.btn-base {
  @apply flex h-10 cursor-pointer flex-row items-center justify-center
  whitespace-nowrap rounded-lg bg-neutral-800
  px-2 py-1 text-white transition-colors duration-200 hover:bg-neutral-900 sm:h-12 sm:px-4 sm:py-2;
}

.btn-primary {
  @extend .btn-base;
  @apply bg-voca-purple hover:bg-voca-purple-700;
}

.btn-white {
  @extend .btn-base;
  @apply bg-white text-black hover:bg-neutral-200;
}

.btn-icon {
  @apply mr-2 h-6 w-6;
}

#DocumentViewer * .page {
  @apply drop-shadow;
}
.v_text_paragraph {
  margin-top: 0;
}

#HtmlRaw > div {
  display: flex;
  flex-direction: column;
}

#HtmlRaw p:first-child {
  margin-top: 0;
}
#HtmlRaw p:last-child {
  margin-bottom: 0;
}
.scrollShadowTop {
  @apply pointer-events-none absolute top-0;
  background: -webkit-linear-gradient(
    270deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.5),
    transparent
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.5),
    transparent
  );
}

.scrollShadowBottom {
  @apply pointer-events-none absolute bottom-0;
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.5),
    transparent
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.5),
    transparent
  );
}
.react-markdown {
  @apply p-0 font-rubik;
  white-space: normal;

  & > :first-child {
    margin-top: 0 !important;
  }

  ul {
    @apply flex flex-col;
    padding-left: 2rem;
    margin-top: 0;
    margin-bottom: 1rem;
    list-style: revert;
  }
  ul ul {
    @apply my-0;
  }
  li {
    @apply text-base;
    float: left;
    margin: 0 !important;
  }
  li + li {
    margin-top: 0.25rem !important;
  }
  h2 {
    @apply border-b border-gray-300 font-rubik text-xl sm:text-2xl;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  h3 {
    @apply font-rubik text-lg sm:text-xl;
    font-weight: 600;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }
}
.react-markdown:after,
.react-markdown:before {
  display: table;
  content: '';
}
.onboarding-content {
  @apply p-0 font-rubik text-base text-neutral-600 sm:text-sm;
  white-space: normal;
  strong {
    @apply text-neutral-700;
  }
  & > :first-child {
    margin-top: 0 !important;
  }

  ul {
    @apply flex flex-col;
    padding-left: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    list-style: revert;
  }
  ul ul {
    @apply my-0;
  }
  li {
    float: left;
    margin: 0 !important;
  }
  li + li {
    margin-top: 0.5rem !important;
  }
  h3 {
    @apply mb-2 font-rubik text-2xl font-normal text-neutral-700;
  }
}
.onboarding-content:after,
.onboarding-content:before {
  display: table;
  content: '';
}
.hr {
  @apply my-4 border-t border-gray-300;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.truncate-1-lines {
  @extend .truncate;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.truncate-2-lines {
  @extend .truncate;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.truncate-3-lines {
  @extend .truncate;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.drawer-container * {
  touch-action: none;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.chatHistory,
.chatHistory * {
  touch-action: pan-y !important;
}
